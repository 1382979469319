import React, {Component} from 'react'
import './index.css'

class Footer extends Component {
    render() {
        return(
            <div className="footer">
                <div className="container">
                    <div className="row">
                        <div className="container">
                            <p>Thanks for stopping by!</p>
                            <p>&copy; {new Date().getFullYear()} Kuhuk Sharma - All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer