import React from 'react';
import Slider from './Slider';
import './shards-dashboards.1.1.0.min.css';

function Blog() {
  return (
    <React.Fragment>
      <div className="align-items-center-in-div">
        <h3 className="text-body-center">posts on medium</h3>
      </div>
      <Slider />
    </React.Fragment>
  );
}

export default Blog;