import React, {Component} from 'react';
import Kuhuk from './Kuhuk';
import Coding from './Coding';
import Astronomy from './Astronomy';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Blog from './Blog';
import ContactMe from './ContactMe';

class AppRouter extends Component {
    render() {
        return(
            <Router>
                <div className="AppRouter">
                    <Routes>
                        <Route exact path='/' element={<Kuhuk />}></Route>
                        <Route exact path='/coding' element={<Coding />}></Route>
                        <Route exact path='/astronomy' element={<Astronomy />}></Route>
                        <Route exact path='/blog' element={<Blog />}></Route>
                        <Route exact path='/contact_me' element={<ContactMe />}></Route>
                    </Routes>
                </div>
            </Router>
        )
    }
}

export default AppRouter