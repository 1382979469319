import React, {Component} from 'react';
import axios from 'axios';

class ContactMe extends Component {

    fetchAPI = (e) => {
        axios.get('http://localhost:5000/hello')
        .then(response => console.log(response.data))
    }

    state = {
        name: '',
        message: '',
        email: '',
        sent: false,
        buttonText: 'Send Message'
    }

    // formSubmit = (e) => {
    //     this.setState({
    //         buttonText: '...sending'
    //     })

    //     //get the form by its id
    //     const form = document.getElementById("contact-form"); 

    //     //1.
    //     const formEvent = form.addEventListener("submit", (event) => {
    //     event.preventDefault();

    //     //2.
    //     let mail = new FormData(form);

    //     //3.
    //     sendMail(mail);
    //     })

    //     const sendMail = (mail) => {
    //     //1.
    //     // fetch("https://nodemailer-vic-lo.herokuapp.com/send")
    //     fetch("http://127.0.0.1:5000/contact", {
    //         method: "post", //2.
    //         body: mail, //3.

    //     }).then((response) => {
    //         return response.json();
    //     });
    //     };
    // }

    // resetForm = () => {
    //     this.setState({
    //         name: '',
    //         message: '',
    //         email: '',
    //         buttonText: 'Message Sent'
    //     })
    // }

    render() {
        return (
            <div className="div-contact-form align-items-center-in-div">
                <p className="text-color-dark">Let's keep in touch!</p>
                <form id="contact-form" action = "https://flask-backend-hlrq.onrender.com/contact" method="POST" className="contact-form container">
                    <div className="col-1 form-category">
                        <input onChange={e => this.setState({ name: e.target.value})} name="name" class="message-name contact-input" type="text" placeholder="Name" required value={this.state.name}/>
                    </div>

                    <div className="col-2 form-category">
                        <input onChange={(e) => this.setState({ email: e.target.value})} name="email" class="message-email contact-input" type="email" placeholder="Email" required value={this.state.email} />
                    </div>

                    <div className="col-3 form-category">
                        <textarea onChange={e => this.setState({ message: e.target.value})} name="message" class="message contact-input" type="text" placeholder="Message" required value={this.state.message}/>
                    </div>

                    <div className="button-container form-category">
                        <button type="submit" value="Submit" className="button button-primary send-button">{ this.state.buttonText }</button>
                    </div>
                </form>
            </div>
        );
    }
}

export default ContactMe;