import React, {Component} from 'react'

class Astronomy extends Component {
    render() {
        return(
            <div className="text-color-dark">
                <div className="align-items-center-in-div">
                    <p>Since I was a little girl, I have always been curious. Questioning everything. And this curiosity has turned me into an Astrophile. I like to read astronomy books and watch sci-fi movies and stargaze of course. In 2020 I bought my very first telescope 🔭 from Celestron and since then I started doing astrophotography. I also communicate science mainly in the field of Astronomy and space sciences, both online and offline.<br/><br/>If you’re looking for a go-to astronomy buddy, I can be one! 🙂 Here is my Instagram page for all the Astro stuff - do check this out and DM me ✨</p>
                    <div id="curator-feed-default-feed-layout"><a href="https://curator.io" target="_blank" class="crt-logo crt-tag">Powered by Curator.io</a></div>
                    <h4 className="text-color-dark">ASTRONOMY EVENTS FOR THIS MONTH</h4>
                    <iframe allowtransparency="yes" scrolling="yes" className="astronomy-calendar" src="https://in-the-sky.org/widgets/newscal.php?skin=0"></iframe>
                </div>
            </div>
        )
    }
}

export default Astronomy