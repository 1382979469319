import React, {Component} from 'react'
import {Navbar, Nav} from 'react-bootstrap'

class Header extends Component {
    render() {
        return(
            <Navbar className="color-nav" variant="light" fixed="top" sticky="top">
                <Navbar.Brand href="/"><p className="brand-logo-text">KUHUK</p></Navbar.Brand>
                <Nav className="ms-auto">
                    <Nav.Link href="/" style={{ textDecoration: 'none' }}><p className="nav-text-color active">home</p></Nav.Link>
                    <Nav.Link href="/coding" style={{ textDecoration: 'none' }}><p className="nav-text-color">coding</p></Nav.Link>
                    <Nav.Link href="/astronomy" style={{ textDecoration: 'none' }}><p className="nav-text-color">astronomy</p></Nav.Link>
                    <Nav.Link href="/blog" style={{ textDecoration: 'none' }}><p className="nav-text-color">write-ups</p></Nav.Link>
                    <Nav.Link href="/contact_me" style={{ textDecoration: 'none' }}><p className="nav-text-color">reach out</p></Nav.Link>
                </Nav>
            </Navbar>
        )
    }
}
export default Header