import React, {Component} from 'react';
import './index.css';

class Coding extends Component {

    componentDidMount() {
      const script = document.createElement("script");
      script.src = "//cdn.jsdelivr.net/github-cards/latest/widget.js";
      script.async = true;

      document.body.appendChild(script);
    }

    render() {
        return(
            <div className="div-home">
                <div className="distribute_horizontally text-color-dark">
                  <div className="distribute_vertically">
                    <div>
                      <p>Skillset</p>
                      <ul>
                        <li>Programming languages: Kotlin, Java, Python, Javascript</li>
                        <li>Technologies & frameworks: Flask, Android, Jetpack Compose, KMM, Ktor, React, MVVM, MVP, HILT</li>
                        <li>Database: SQLite, Room, Realm, Firebase Realtime DB, PostgreSQL</li>
                        <li>Tools: Git, Firebase, GooglePlay console, Notion, Figma, Render, Netlify</li>
                        <li>Professional skills: Technical documentation, Team leadership</li>
                      </ul>
                    </div>
                    <div className="margin-at-top">
                      <p>Employers</p>
                      <ul>
                        <li>Internshala</li>
                        <li>EzeiaTech Systems</li>
                        <li>Mobiloitte</li>
                      </ul>
                    </div>
                  </div>
                  <div>
                    <p>GitHub Profile</p>
                      <div class="github-card" data-github="kuhuk" data-width="300" data-height="318" data-theme="medium"></div>
                  </div>
                </div>
            </div>
        );
    }
}

export default Coding
 